import ClientFeedbackCard from '../client-feedback-card/client-feedback-card';

function FeedbackPage() {
    return (
        <div id="feedback-page" className="feedback-page">
            <ClientFeedbackCard clientName="meetMargo"></ClientFeedbackCard>
        </div>
    );
}
export default FeedbackPage;
